import { Button, Row, Space, Divider } from "antd";

import {
  PaymentType,
  OtherPaymentCategory,
  routePathByPage,
  Permission,
} from "~/constants";
import {
  Allow,
  PaymentOverviewCard,
  Comments,
  ActivityLogs,
} from "~/components";
import { useCheckPermissions } from "~/hooks";

import { usePaymentDetails } from "../hooks";
import { PaymentHeader } from "../PaymentHeader";

import { useMarkAsUnidentified } from "./useMarkAsUnidentified";

const allowedForMarkAsUnidentifiedCategories = [
  OtherPaymentCategory.Refund,
  OtherPaymentCategory.RatenkaufFinancing,
  OtherPaymentCategory.OnHold,
  OtherPaymentCategory.FinancingKickback,
  OtherPaymentCategory.Internal,
  OtherPaymentCategory.NotApplicable,
  OtherPaymentCategory.PenaltyFees,
];

export function InternalPaymentView() {
  const { data: payment } = usePaymentDetails(PaymentType.Internal);
  const hasMarkAsUnidentifiedPermission = useCheckPermissions(
    Permission.markInternalAsUnidentifiedPayment
  );
  const { isLoading: isMarking, mutate: markAsUnidentified } =
    useMarkAsUnidentified();

  return payment ? (
    <>
      <PaymentHeader
        payment={payment}
        navigationPath={routePathByPage.internalView}
      />
      <PaymentOverviewCard mode="view" payment={payment} />
      <Row justify="end" style={{ marginTop: 16 }}>
        <Space>
          {hasMarkAsUnidentifiedPermission &&
            allowedForMarkAsUnidentifiedCategories.includes(
              payment.category
            ) && (
              <Button
                type="primary"
                loading={isMarking}
                onClick={() => markAsUnidentified(payment.id)}
              >
                Mark as unidentified
              </Button>
            )}
        </Space>
      </Row>
      <Divider />
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        <Allow perm={Permission.queryInternalPaymentComments}>
          <Comments paymentType={PaymentType.Internal} payment={payment} />
        </Allow>
        <Allow perm={Permission.queryInternalPaymentActivityLogs}>
          <ActivityLogs payment={payment} />
        </Allow>
      </Space>
    </>
  ) : null;
}
