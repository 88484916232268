import { useEffect, useState } from "react";
import { Table } from "antd";

import { Comment } from "~/adapters";
import { PaymentType, Permission } from "~/constants";

import { Allow } from "../Allow";
import { SingleCollapse, OpenMode } from "../SingleCollapse";

import { CommentForm } from "./CommentForm";
import { useTableColumns } from "./hooks";

const createPermByType = {
  [PaymentType.Successful]: Permission.createSuccessfulPaymentComment,
  [PaymentType.Internal]: Permission.createInternalPaymentComment,
  [PaymentType.Unidentified]: Permission.createUnidentifiedPaymentComment,
};

interface CommentsProps {
  paymentType: PaymentType;
  payment: {
    id: string;
    comments?: Comment[];
  };
  openMode?: OpenMode;
}

export function Comments({
  paymentType,
  payment,
  openMode = "by-default",
}: CommentsProps) {
  const [comments, setComments] = useState(() => payment.comments ?? []);
  const columns = useTableColumns();

  useEffect(() => {
    setComments(payment.comments ?? []);
  }, [payment.comments]);

  return (
    <SingleCollapse
      id="comments"
      header={`Comments (${comments.length})`}
      openMode={openMode}
    >
      <Allow perm={createPermByType[paymentType]}>
        <CommentForm
          paymentType={paymentType}
          paymentId={payment.id}
          onSuccess={(newComment) =>
            setComments((comments) => [...comments, newComment])
          }
        />
      </Allow>
      <Table rowKey="id" dataSource={comments} columns={columns} />
    </SingleCollapse>
  );
}
