import { CommentFragment } from "~/graphql";

export const unidentifiedPaymentsQuery = /* GraphQL */ `
  query ($search: SearchProjection2Input!, $withComments: Boolean!) {
    searchRetailUnidentifiedPayments(search: $search) {
      totalEntityCount
      entities {
        externalId
        id
        accountingDate
        status
        paymentData {
          bankAccount
          paymentDate
          bankReferenceNumber
          endToEndReference
          customerPaymentReference
          bic
          iban
          accountHolder
          amount
          countryCode
          currencyCode {
            description
            country
          }
          paymentType
        }
        uploadDate
        comments @include(if: $withComments) {
          ...CommentFragment
        }
      }
    }
  }
  ${CommentFragment}
`;
