import { Table } from "antd";

import { ActivityLog } from "~/adapters";

import { SingleCollapse, OpenMode } from "../SingleCollapse";

import { columns } from "./columns";

interface ActivityLogsProps {
  payment: {
    activityLogs?: ActivityLog[];
  };
  openMode?: OpenMode;
}

export function ActivityLogs({ payment, openMode }: ActivityLogsProps) {
  const activityLogs = payment.activityLogs ?? [];

  return (
    <SingleCollapse
      id="activityLogs"
      header={`Change Log (${activityLogs.length})`}
      openMode={openMode}
    >
      <Table
        rowKey="id"
        showHeader={false}
        bordered
        dataSource={activityLogs}
        columns={columns}
      />
    </SingleCollapse>
  );
}
