import { PaymentType, Permission } from "~/constants";

import {
  successfulPaymentsQuery,
  otherPaymentsQuery,
  unidentifiedPaymentsQuery,
} from "./queries";

type Config = {
  query: string;
  commentsPerm: Permission;
};

export const configByPaymentType: Record<PaymentType, Config> = {
  [PaymentType.Successful]: {
    query: successfulPaymentsQuery,
    commentsPerm: Permission.querySuccessfulPaymentsComments,
  },
  [PaymentType.Internal]: {
    query: otherPaymentsQuery,
    commentsPerm: Permission.queryInternalPaymentsComments,
  },
  [PaymentType.Unidentified]: {
    query: unidentifiedPaymentsQuery,
    commentsPerm: Permission.queryUnidentifiedPaymentsComments,
  },
};
