import { TableColumnsType, TableColumnType } from "antd";

import { ActivityLog } from "~/adapters";
import { PaymentTypeTitle } from "~/constants";
import { formatDate } from "~/utils";

export const columns: TableColumnsType<ActivityLog> = [
  {
    title: "Payment type",
    dataIndex: "paymentType",
    key: "paymentType",
    render: (paymentType: ActivityLog["paymentType"]) =>
      PaymentTypeTitle[paymentType],
  },
  {
    title: "Text",
    dataIndex: "value",
    key: "value",
  },
  {
    title: "Date",
    dataIndex: "createdOn",
    key: "createdOn",
    render: (createdOn: ActivityLog["createdOn"]) =>
      formatDate(createdOn, true),
  },
  {
    title: "Created by",
    dataIndex: "createdBy",
    key: "createdBy",
    render: (createdBy: ActivityLog["createdBy"]) =>
      createdBy && `${createdBy.firstName} ${createdBy.lastName}`,
  },
].map((column: TableColumnType<ActivityLog>) => ({
  ...column,
  render: (value: any, ...restArgs) =>
    column.render?.(value, ...restArgs) || value || "-",
}));
