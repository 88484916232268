import { CommentFragment } from "~/graphql";

export const successfulPaymentsQuery = /* GraphQL */ `
  query ($search: SearchProjection2Input!, $withComments: Boolean!) {
    searchRetailPayments(search: $search) {
      totalEntityCount
      entities {
        paymentDate
        accountDetails {
          holder
          bic
          iban
        }
        accountingDate
        amount {
          amountMinorUnits
          currencyCode
        }
        countryCode
        createdOn
        externalId
        id
        orderId
        type
        importDetails {
          transactionCode
          recipientBankAccount
        }
        comments @include(if: $withComments) {
          ...CommentFragment
        }
      }
    }
  }
  ${CommentFragment}
`;
