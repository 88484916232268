import { CommentFragment, ActivityLogFragment } from "../fragments";

export const successfulPaymentQuery = /* GraphQL */ `
  query ($paymentId: UUID!) {
    retailPayment: retailPayment(id: $paymentId) {
      id
      importDetails {
        recipientBankAccount
        customerPaymentReference
      }
      accountDetails {
        bic
        holder
        iban
      }
      accountingDate
      amount {
        amountMinorUnits
        currencyCode
      }
      countryCode
      createdOn
      creationSource
      externalPaymentSystem
      externalId
      orderId
      type
      comments {
        ...CommentFragment
      }
      activityLogs {
        ...ActivityLog
      }
    }
  }
  ${CommentFragment}
  ${ActivityLogFragment}
`;

export const successfulPaymentOrderQuery = /* GraphQL */ `
  query ($orderId: UUID!) {
    order: order(id: $orderId) {
      orderNumber
      stockNumber
      customer {
        firstName
        lastName
      }
    }
  }
`;
