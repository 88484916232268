export const ActivityLogFragment = /* GraphQL */ `
  fragment ActivityLog on RetailPaymentActivityLogProjection {
    id
    paymentType
    value
    createdBy {
      firstName
      lastName
    }
    createdOn
  }
`;
