import { useRef, useCallback } from "react";
import { Form, Input, InputRef, Space, Button } from "antd";

import { Comment } from "~/adapters";
import { PaymentType } from "~/constants";

import { useCreateCommentMutation } from "./hooks";

interface CommentFormProps {
  paymentType: PaymentType;
  paymentId: string;
  onSuccess: (comment: Comment) => void;
}

export function CommentForm({
  paymentType,
  paymentId,
  onSuccess,
}: CommentFormProps) {
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);
  const focusInput = useCallback(() => {
    requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  }, []);
  const { isLoading, mutate } = useCreateCommentMutation({
    paymentType,
    paymentId,
    onSuccess: (newComment) => {
      onSuccess(newComment);
      form.resetFields();
      focusInput();
    },
  });

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      disabled={isLoading}
      onFinish={mutate}
      onReset={focusInput}
    >
      <Form.Item name="comment" rules={[{ required: true }]}>
        <Input.TextArea ref={inputRef} />
      </Form.Item>
      <Space size="small">
        <Button htmlType="reset">Cancel</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </Space>
    </Form>
  );
}
