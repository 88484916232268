import {
  SuccessfulPayment,
  OtherPayment,
  UnidentifiedPayment,
} from "~/adapters";
import { Domain, PaymentType } from "~/constants";

import { callGql } from "../../../utils/apiClient";

import { gqlConfigByType } from "./constants";

export type PaymentByType = {
  successful: SuccessfulPayment;
  internal: OtherPayment;
  unidentified: UnidentifiedPayment;
};

export const fetchPaymentDetails = async <T extends PaymentType>(
  paymentType: T,
  paymentId: string,
  orderId?: string
): Promise<PaymentByType[T]> => {
  const gqlConfig = gqlConfigByType[paymentType];
  const { query, adapter } = gqlConfig;

  const responses = await Promise.all([
    callGql({
      query,
      variables: { paymentId },
      domain: Domain.PAYMENTS,
    }),
    "orderQuery" in gqlConfig && orderId
      ? callGql({
          query: gqlConfig.orderQuery,
          variables: { orderId },
          domain: Domain.ORDER,
        })
      : Promise.resolve({ data: undefined, errors: undefined }),
  ]);

  const { data, errors } = responses.reduce(
    (aggregatedRes, res) => ({
      data: { ...(aggregatedRes?.data ?? {}), ...(res?.data ?? {}) },
      errors: [...(aggregatedRes?.errors ?? []), ...(res?.errors ?? [])],
    }),
    {}
  );

  if (errors?.length) {
    throw errors[0].exception?.localizedMessage;
  }

  return adapter(data) as PaymentByType[T];
};
