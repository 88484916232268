import { CommentFragment, ActivityLogFragment } from "../fragments";

export const otherPaymentQuery = /* GraphQL */ `
  query ($paymentId: UUID!) {
    internalTransferPaymentById(id: $paymentId) {
      id
      importDetails {
        recipientBankAccount
        customerPaymentReference
      }
      accountData {
        bic
        holder
        iban
      }
      category
      accountingDate
      amount {
        amountMinorUnits
        currencyCode
      }
      countryCode
      createdOn
      externalId
      type
      creationSource
      comments {
        ...CommentFragment
      }
      activityLogs {
        ...ActivityLog
      }
    }
  }
  ${CommentFragment}
  ${ActivityLogFragment}
`;
