import { Button, Form, Space, Divider } from "antd";

import { SuccessfulPayment } from "~/adapters";
import { Allow, Comments, ActivityLogs } from "~/components";
import { Permission, PaymentType, PaymentMethod } from "~/constants";

import { routePathByPage, SuccessfulEditPermission } from "../../constants";
import { useHasPermissions } from "../../hooks";
import { PaymentOverviewCard } from "../../components";
import { PaymentField } from "../../components/PaymentOverviewCard";
import { PaymentHeader } from "../PaymentHeader";
import { usePaymentDetails } from "../hooks";

import { useUpdateSuccessfulPayment } from "./useUpdateSuccessfulPayment";

const canUpdateSuccessfulPaymentMethod = (method?: PaymentMethod) =>
  method === "WIRE_TRANSFER_CUSTOMER" ||
  method === "WIRE_TRANSFER_BANK_FINANCE";

export const SuccessfulPaymentView = () => {
  const { data: payment } = usePaymentDetails(PaymentType.Successful);
  const hasPermissions = useHasPermissions();
  const canUpdateSuccessfulPayment = hasPermissions(
    SuccessfulEditPermission.updateSuccessfulPayment
  );

  const [form] = Form.useForm<SuccessfulPayment>();
  const updatePaymentMutation = useUpdateSuccessfulPayment(payment?.id);
  const editableFields = [
    canUpdateSuccessfulPaymentMethod(payment?.paymentType)
      ? "paymentType"
      : null,
  ].filter(Boolean) as PaymentField[];

  return payment ? (
    <>
      <PaymentHeader
        payment={payment}
        navigationPath={routePathByPage.successfulView}
      />
      {canUpdateSuccessfulPayment ? (
        <Form
          form={form}
          initialValues={payment}
          onFinish={updatePaymentMutation.mutate}
        >
          <PaymentOverviewCard
            mode="edit"
            editableFields={editableFields}
            payment={payment}
          />
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={updatePaymentMutation.isLoading}
              loading={updatePaymentMutation.isLoading}
            >
              Save
            </Button>
          </Space>
        </Form>
      ) : (
        <PaymentOverviewCard mode="view" payment={payment} />
      )}
      <Divider />
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        <Allow perm={Permission.querySuccessfulPaymentComments}>
          <Comments paymentType={PaymentType.Successful} payment={payment} />
        </Allow>
        <Allow perm={Permission.querySuccessfulPaymentActivityLogs}>
          <ActivityLogs payment={payment} />
        </Allow>
      </Space>
    </>
  ) : null;
};
