import { ReactNode, PropsWithChildren } from "react";
import { Collapse } from "antd";
import cns from "classnames";

import cn from "./styles.less";

export type OpenMode = "by-default" | "always";

type SingleCollapseProps = PropsWithChildren<{
  id: string;
  header: ReactNode;
  openMode?: OpenMode;
}>;

export function SingleCollapse({
  id,
  header,
  openMode,
  children,
}: SingleCollapseProps) {
  const isAlwaysOpen = openMode === "always";

  return (
    <Collapse
      className={cns(cn.singleCollapse, {
        [cn.isAlwaysOpen]: isAlwaysOpen,
      })}
      {...(openMode === "by-default" && { defaultActiveKey: id })}
      {...(isAlwaysOpen && { activeKey: id })}
    >
      <Collapse.Panel key={id} header={header} showArrow={!isAlwaysOpen}>
        {children}
      </Collapse.Panel>
    </Collapse>
  );
}
