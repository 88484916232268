import {
  successfulPaymentQuery,
  successfulPaymentOrderQuery,
  otherPaymentQuery,
  unidentifiedPaymentQuery,
} from "~/graphql";
import {
  successfulPaymentAdapter,
  otherPaymentAdapter,
  unidentifiedPaymentAdapter,
} from "~/adapters";
import { PaymentType } from "~/constants";

export const gqlConfigByType = {
  [PaymentType.Successful]: {
    query: successfulPaymentQuery,
    orderQuery: successfulPaymentOrderQuery,
    adapter: successfulPaymentAdapter,
  },
  [PaymentType.Internal]: {
    query: otherPaymentQuery,
    adapter: otherPaymentAdapter,
  },
  [PaymentType.Unidentified]: {
    query: unidentifiedPaymentQuery,
    adapter: unidentifiedPaymentAdapter,
  },
};
