import { Divider, Space } from "antd";

import { Allow, Comments, ActivityLogs } from "~/components";
import { Permission, PaymentType } from "~/constants";

import { routePathByPage } from "../../constants";
import { PaymentOverviewCard } from "../../components";
import { usePaymentDetails } from "../hooks";
import { PaymentHeader } from "../PaymentHeader";

export function UnidentifiedPaymentView() {
  const { data: payment } = usePaymentDetails(PaymentType.Unidentified);

  return payment ? (
    <>
      <PaymentHeader
        payment={payment}
        navigationPath={routePathByPage.unidentifiedView}
      />
      <PaymentOverviewCard mode="view" payment={payment} />
      <Divider />
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        <Allow perm={Permission.queryUnidentifiedPaymentComments}>
          <Comments paymentType={PaymentType.Unidentified} payment={payment} />
        </Allow>
        <Allow perm={Permission.queryUnidentifiedPaymentActivityLogs}>
          <ActivityLogs payment={payment} />
        </Allow>
      </Space>
    </>
  ) : null;
}
